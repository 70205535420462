import React from 'react';

const Title = props => {
  const { intro = {} } = props;
  const introImageClasses = `intro-image ${intro.frontmatter?.intro_image_absolute && 'intro-image-absolute'} ${
    intro.frontmatter?.intro_image_hide_on_mobile && 'intro-image-hide-mobile'
  }`;
  return (
    <div className="intro-xs">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">{props.children}</div>
          {intro.frontmatter?.intro_image && (
            <div className="col-12 position-relative">
              <img alt={intro.frontmatter?.title} className={introImageClasses} src={intro.frontmatter?.intro_image} />
            </div>
          )}
          <div className="col-12 justify-content-center hstack">
            <div className="indicator"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Title;
